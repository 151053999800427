<template>
  <div
    class="fixed top-0 left-0 w-full h-full flex lg:items-center justify-center z-50"
  >
    <transition name="fade">
      <div v-if="isOpen">
        <div
          class="absolute top-0 left-0 bg-black opacity-80 w-full h-full"
          @click="close"
        ></div>
      </div>
    </transition>
    <transition name="modal-slide">
      <div
        v-if="isOpen"
        class="modal-inner bg-white p-6 pt-5 lg:p-8 relative overflow-y-auto rounded-3xl lg:rounded-4xl"
        style="max-height: 90vh"
      >
        <div class="absolute top-6 right-6 sidebar-close z-20" @click="close">
          <IconsCloseIcon />
        </div>
        <slot />
      </div>
    </transition>
  </div>
</template>

<script setup lang="ts">
const emits = defineEmits(["close"]);
const isOpen = ref(false);
const modalId = `modal-${Math.random().toString(36).substring(2, 9)}`;
const modalStore = useModalStore();

onMounted(() => {
  modalStore.registerModal(modalId);
  isOpen.value = true;
});

useEventListener("keydown", (event) => {
  if (event.key === "Escape" && isOpen.value) {
    if (modalStore.isTopModal(modalId)) {
      modalStore.unregisterModal(modalId);
      close();
    }
  }
});

function close() {
  emits("close");
}
</script>
