export const useModalStore = defineStore("modal", {
  state: () => ({
    modalsStack: [] as string[],
  }),

  actions: {
    registerModal(modalId: string) {
      this.modalsStack.push(modalId);
    },

    unregisterModal(modalId: string) {
      this.modalsStack = this.modalsStack.filter((id) => id !== modalId);
    },
  },

  getters: {
    isTopModal: (state) => (modalId: string) => {
      return state.modalsStack[state.modalsStack.length - 1] === modalId;
    },
  },
});

